import { useTable, useFilters, useSortBy } from "react-table";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "./AppContext";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import FilterDialog from "./FilterDialog";

export function ReactTable(props) {
  const { ctxPlannen, ctxSetSelectedPlan, ctxTableFilter } =
    useContext(AppContext);

  const [selectedRow, setSelectedRow] = useState(null);

  //filter van de tabel is gewijzigd (omdat een feature op de map is aangeklikt bv)
  useEffect(() => {
    if (props.columns.length > 0) {
      if (ctxTableFilter.length === 0) {
        setAllFilters([]);
      } else {
        setAllFilters(ctxTableFilter);
      }
    }
  }, [ctxTableFilter]);

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <div
        style={{
          border: "0px solid red",
          bottom: "0px",
          width: "100%",
        }}
      >
        <input
          value={filterValue || ""}
          onChange={(e) => {
            setSelectedRow(null);
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`Filter...`}
          style={{ width: "75%", verticalAlign: "top" }}
        />
      </div>
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = props.columns;
  const data = props.rows;
  const hidden = props.hidden;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setAllFilters,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: hidden,
      },
    },
    useFilters,
    useSortBy
  );

  const onclickrow = (evt) => {
    if (selectedRow === evt.target.parentNode.id) {
      setSelectedRow(null);
      ctxSetSelectedPlan(null);
      console.log("SelectedPlan: none");
    } else {
      setSelectedRow(evt.target.parentNode.id);
      var plan = ctxPlannen.filter((plan) => {
        return (
          plan.attributes[window.Objectid].toString() ===
          evt.target.parentNode.id
        );
      });
      ctxSetSelectedPlan(plan[0]);
      console.log("SelectedPlan: ", plan[0]);
    }
  };

  return (
    <div style={{ padding: "15px", overflow: "auto" }}>
      <table
        {...getTableProps()}
        style={{
          border: "solid 1px " + window.Color,
          width: "100%",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    background: "white",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                    verticalAlign: "top",
                    position: "relative",
                    width: column.width,
                    whiteSpace: "nowrap"
                  }}
                >
                  {column.render("Header")}
                  <br />
                  <ImportExportIcon fontSize="small"></ImportExportIcon>
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                id={row.cells[0].value} //cells[0] is 1e kolom
                onClick={onclickrow}
                style={{
                  background:
                    row.cells[0].value.toString() === selectedRow
                      ? "#00afec"
                      : "white",
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "5px",
                        borderBottom: "solid 1px gray",
                        //background: "white",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <FilterDialog
        isfilterdialogopen={props.isfilterdialogopen}
        closefilterdialog={props.closefilterdialog}
      ></FilterDialog>
    </div>
  );
}
