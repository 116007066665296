import { deleteFeatures } from "@esri/arcgis-rest-feature-layer";
import { NotifySuccess, NotifyFailure } from "./Notify";

export async function deleteRecord(
  parentglobalId,
  jaartal,
  urlrelated,
  usersession,
  callback
) {
  //related record verwijderen
  deleteFeatures({
    url: urlrelated,
    authentication: usersession,
    where: "parent_globalid='" + parentglobalId + "' AND jaartal=" + jaartal,
  })
    .then((response) => {
      console.log("DeleteJaartal gelukt: " + jaartal);
      NotifySuccess("Verwijderen " + jaartal, "Gereed");
      callback();
    })
    .catch((e) => {
      NotifyFailure("Verwijderen " + jaartal, "Mislukt: " + e.message);
    });
}
