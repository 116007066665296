import { queryFeatures } from "@esri/arcgis-rest-feature-layer";

export async function queryFeaturesUrl(url, usersession, where, start) {
  console.log("where: ", where);
  return queryFeatures({
    url: url,
    where: where,
    resultOffset: start,
    authentication: usersession,
  });
}
