import React, { createContext, useState } from "react";

const AppContext = createContext();

function AppContextProvider({ children }) {
  const [ctxSelectedPlan, ctxSetSelectedPlan] = useState(null); ////het geselecteerde plan als feature
  const [ctxFeaturelayerUrl, ctxSetFeaturelayerUrl] = useState(null); //de url naar de featurelayer, gehaald uit de webmap
  const [ctxFeaturelayer, ctxSetFeaturelayer] = useState(null); //de featurelayer, gehaald uit de webmap
  const [ctxDetailTableUrl, ctxSetDetailTableUrl] = useState(null); //de url naar de detailtable, gehaald uit de webmap
  const [ctxPlannen, ctxSetPlannen] = useState([]); //alle plannen in de view van de webmap
  const [ctxPlannenFields, ctxSetPlannenFields] = useState([]); //alle velden van de plannen in de view van de webmap
  const [ctxCredentials, ctxSetCredentials] = useState(null);
  const [ctxDetailRecordsValid, ctxSetDetailRecordsValid] = useState(true);
  const [ctxWebMapId, ctxSetWebMapId] = useState(null);
  const [ctxUserSession, ctxSetUserSession] = useState(null);
  const [ctxGemeentenaam, ctxSetGemeentenaam] = useState("");
  const [ctxPlaatsnamen, ctxSetPlaatsnamen] = useState(null);
  const [ctxGemeenteExtent, ctxSetGemeenteExtent] = useState(null);
  const [ctxTableFilter, ctxSetTableFilter] = useState([]);

  //   function eenofanderefunctie(value) {
  //     setDatamodel(value);
  //   }

  const defaultContext = {
    ctxSelectedPlan,
    ctxSetSelectedPlan,
    ctxFeaturelayerUrl,
    ctxSetFeaturelayerUrl,
    ctxFeaturelayer,
    ctxSetFeaturelayer,
    ctxDetailTableUrl,
    ctxSetDetailTableUrl,
    ctxPlannen,
    ctxSetPlannen,
    ctxCredentials,
    ctxSetCredentials,
    ctxWebMapId,
    ctxSetWebMapId,
    ctxUserSession,
    ctxSetUserSession,
    ctxTableFilter,
    ctxSetTableFilter,
    ctxPlannenFields,
    ctxSetPlannenFields,
    ctxDetailRecordsValid,
    ctxSetDetailRecordsValid,
    ctxPlaatsnamen,
    ctxSetPlaatsnamen,
    ctxGemeentenaam,
    ctxSetGemeentenaam,
    ctxGemeenteExtent,
    ctxSetGemeenteExtent,
    // eenofanderefunctie,
  };

  return (
    <AppContext.Provider value={defaultContext}>{children}</AppContext.Provider>
  );
}

export { AppContext, AppContextProvider };
