import { deleteRecord } from "./DeleteRecord";
import { updateFeatures } from "@esri/arcgis-rest-feature-layer";
import { addPlanRelatedJaar } from "./AddPlanRelated";
import { NotifySuccess, NotifyFailure } from "./Notify";

export async function addRemoveDetailRecords(
  selectedPlanAttr,
  urltable,
  usersession,
  jaareerste,
  jaarlaatste,
  callback
) {
  let globalid = selectedPlanAttr[window.Globalid];
  const params = {
    where: "parent_globalid='" + globalid + "'",
    f: "json",
    orderByFields: "jaartal",
    outFields: ["*"],
    token: usersession.token,
  };
  const paramVals = [];
  for (const k in params) {
    const val = encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    paramVals.push(val);
  }
  const url = `${urltable}/query?${paramVals.join("&")}`;
  const data = await fetch(url);
  const { features } = await data.json();

  //Alleen maar iets doen als er al records zijn. Het initieel aanmaken verloopt via AddPlanRelated
  if (features?.length > 0) {
    let jaren_in_db = [];
    for (let index = 0; index < features.length; index++) {
      jaren_in_db.push(features[index].attributes["jaartal"]);
    }
    let jaren_volgens_eerstelaatste = [];
    for (let jaar = jaareerste; jaar <= jaarlaatste; jaar++) {
      jaren_volgens_eerstelaatste.push(parseInt(jaar));
    }

    console.log("JAREN IN DB: ", jaren_in_db);
    console.log("JAREN EERSTELAATSTE: ", jaren_volgens_eerstelaatste);

    var db_not_in_el = jaren_in_db.filter(function (i) {
      return this.indexOf(i) < 0;
    }, jaren_volgens_eerstelaatste);
    var el_not_in_db = jaren_volgens_eerstelaatste.filter(function (i) {
      return this.indexOf(i) < 0;
    }, jaren_in_db);

    //records in de database welke niet meer in de range eerste-laatste zitten (moeten worden verwijderd uit de database)
    console.log("DB not in EL (verwijderen): ", db_not_in_el);
    db_not_in_el.forEach((jaartal) => {
      deleteRecord(globalid, jaartal, urltable, usersession, callback);
    });

    //records in de range eerste-laatste welke nog niet in de database zitten (moeten worden toegevoegd in de database)
    console.log("EL not in DB (toevoegen): ", el_not_in_db);
    el_not_in_db.forEach((jaartal) => {
      addPlanRelatedJaar(
        selectedPlanAttr,
        globalid,
        urltable,
        jaartal,
        usersession,
        callback
      );
    });
  }
}

export async function shiftDetailRecords(
  selectedPlanAttr,
  urltable,
  usersession,
  callback
) {
  let globalid = selectedPlanAttr[window.Globalid];
  const params = {
    where: "parent_globalid='" + globalid + "'",
    f: "json",
    orderByFields: "jaartal",
    outFields: ["*"],
    token: usersession.token,
  };
  const paramVals = [];
  for (const k in params) {
    const val = encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    paramVals.push(val);
  }
  const url = `${urltable}/query?${paramVals.join("&")}`;
  const data = await fetch(url);
  const { features } = await data.json();

  //Alleen maar iets doen als er al records zijn. Het initieel aanmaken verloopt via AddPlanRelated
  if (features?.length > 0) {
    //maak een array van de records met opgehoogd jaartal
    let jarenToShift = [];
    for (let index = 0; index < features.length; index++) {
      var ft = {};
      ft = {
        attributes: {
          objectid: features[index].attributes[window.Objectid],
          jaartal: features[index].attributes["jaartal"] + 1,
        },
      };
      jarenToShift.push(ft);
    }

    //stuur in 1 array alle updates door
    updateFeatures({
      url: urltable,
      authentication: usersession,
      features: jarenToShift,
    })
      .then((response) => {
        console.log("Updated feature: ", response);
        if (response.updateResults[0].success) {
          NotifySuccess("Wijziging", "Opgeslagen");
          if (callback) callback();
        } else {
          NotifyFailure(
            "Wijziging",
            "Opslaan mislukt: " + response.updateResults[0].error.description
          );
        }
      })
      .catch((e) => {
        NotifyFailure("Wijziging", "Opslaan mislukt: " + e.message);
      });
  }
}

export async function UpdateVertrouwelijkheidDetailRecords(
  selectedPlanAttr,
  urltable,
  usersession
) {
  console.log(
    "Update vertrouwelijkheid detailrecords naar: " +
      selectedPlanAttr["vertrouwelijkheid"]
  );

  let globalid = selectedPlanAttr[window.Globalid];
  const params = {
    where: "parent_globalid='" + globalid + "'",
    f: "json",
    outFields: ["*"],
    token: usersession.token,
  };
  const paramVals = [];
  for (const k in params) {
    const val = encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    paramVals.push(val);
  }
  const url = `${urltable}/query?${paramVals.join("&")}`;
  const data = await fetch(url);
  const { features } = await data.json();

  //Alleen maar iets doen als er records zijn uiteraard.
  if (features?.length > 0) {
    //maak een array van de records met aangepaste vertrouwelijkheid
    let updatedFeatures = [];
    for (let index = 0; index < features.length; index++) {
      var ft = {};
      ft = {
        attributes: {
          objectid: features[index].attributes[window.Objectid],
          vertrouwelijkheid: selectedPlanAttr["vertrouwelijkheid"],
        },
      };
      updatedFeatures.push(ft);
    }

    //stuur in 1 array alle updates door
    updateFeatures({
      url: urltable,
      authentication: usersession,
      features: updatedFeatures,
    })
      .then((response) => {
        if (response.updateResults[0].success) {
          NotifySuccess("Wijziging", "Opgeslagen");
        } else {
          NotifyFailure(
            "Wijziging",
            "Opslaan mislukt: " + response.updateResults[0].error.description
          );
        }
      })
      .catch((e) => {
        NotifyFailure("Wijziging", "Opslaan mislukt: " + e.message);
      });
  }
}
