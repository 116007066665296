import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import EditMap from "./EditMap";
import { AppContext } from "./AppContext";
import { getPlanQuery } from "./GetPlan";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPlanDialog(props) {
  const classes = useStyles();

  const { ctxFeaturelayer } = useContext(AppContext);

  const handleClose = () => {
    props.closedialog(null);
  };

  async function getAddedPlan(objid) {
    getPlanQuery(ctxFeaturelayer, objid).then((result) => {
      if (result.features?.length > 0) {
        console.log("GETPLANQUERY: ", result.features[0]);
        props.closedialog(result.features[0]);
      } else {
        console.log("GETPLANQUERY: no result");
        props.closedialog(null);
      }
    });
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{ backgroundColor: window.Color }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Aanmaken nieuw plan
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="NewdivTop">
          <div className="Newdivchild" style={{ width: "100%" }}>
            <EditMap
              editing={true}
              mode="create"
              addedplan={getAddedPlan}
            ></EditMap>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
