import React, { useRef, useEffect, useState, useContext } from "react";
import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import LayerList from "@arcgis/core/widgets/LayerList";
import Expand from "@arcgis/core/widgets/Expand";
import { AppContext } from "./AppContext";
import "./App.css";
import { queryFeaturesUrl } from "./QueryFeatures";
import esriConfig from "@arcgis/core/config.js";

export function MainMap() {
  const mapRef = useRef();
  const [mapview, setMapView] = useState(null);
  const mapviewRef = useRef(null);
  const lyrRef = useRef(null);

  const {
    ctxSetFeaturelayerUrl,
    ctxSetFeaturelayer,
    ctxSetDetailTableUrl,
    ctxPlannen,
    ctxSelectedPlan,
    ctxWebMapId,
    ctxUserSession,
    ctxSetTableFilter,
    ctxSetPlaatsnamen,
    ctxSetGemeentenaam,
    ctxSetGemeenteExtent,
  } = useContext(AppContext);

  const UitlezenGemeentenaam = () => {
    console.log("UitlezenGemeentenaam");
    //template uitlezen en zoeken naar gemeentenaam
    let gemeente = "";
    if (lyrRef.current.templates.length > 0) {
      gemeente = lyrRef.current.templates[0].prototype.attributes.gemeente;
      ctxSetGemeentenaam(gemeente);
    }

    if (gemeente === "" || gemeente === undefined || gemeente === null) {
      alert("Gemeentenaam onbekend");
      console.log("Gemeentenaam onbekend");
      return;
    } else {
      OphalenWoonplaatsenBijGemeente(window.GemeentenTabelUrl, gemeente);
      console.log("Gemeentenaam: " + gemeente);
    }
  };

  const OphalenWoonplaatsenBijGemeente = (layer, gemeente) => {
    // query uitvoeren op de featurelayer om de lijst plaatsnamen op te halen
    let where = "gemeentenaam='" + gemeente + "'";
    queryFeaturesUrl(layer, ctxUserSession, where, 0)
      .then((result) => {
        console.log("Plaatsnamen: ", result);
        let plaatsnamen = [];
        for (let index = 0; index < result.features.length; index++) {
          let cv = {};
          cv["code"] =
            result.features[index].attributes.woonplaats == undefined
              ? result.features[index].attributes.plaatsnaam
              : result.features[index].attributes.woonplaats;
          cv["name"] =
            result.features[index].attributes.woonplaats == undefined
              ? result.features[index].attributes.plaatsnaam
              : result.features[index].attributes.woonplaats;
          plaatsnamen.push(cv);
        }
        ctxSetPlaatsnamen(plaatsnamen);
      })
      .catch((e) => {
        console.log("Plaatsnamen failed: ", e);
      });
  };

  useEffect(() => {
    console.log("useeffect mainmap");
    //https://developers.arcgis.com/javascript/latest/api-reference/esri-WebMap.html
    //To load a Webmap from an on-premise portal, set the portal url in esriConfig.portalurl.
    if (window.PortalUrl.includes("portal")) {
      esriConfig.portalUrl = window.PortalUrl;
    }
    if (ctxWebMapId == null) return;
    const map = new WebMap({
      portalItem: {
        id: ctxWebMapId,
      },
    });
    const view = new MapView({
      container: mapRef.current,
      map: map,
      center: [-118, 34],
      zoom: 8,
    });

    setMapView(view);
    mapviewRef.current = view;

    //LayerList-widget toevoegen aan de kaart
    view.when(function () {
      var layerList = new LayerList({
        view: view,
      });

      var codeExpand = new Expand({
        expandIconClass: "esri-icon-layer-list",
        expandTooltip: "Toon lijst met lagen",
        view: view,
        content: layerList,
        expanded: false,
      });
      //add the panel to the UI
      view.ui.add(codeExpand, "top-right");
    });

    //https://developers.arcgis.com/javascript/latest/api-reference/esri-views-View.html#properties-summary
    var handleReady = view.watch(
      "ready",
      function (newValue, oldValue, propertyName, target) {
        //loop over featurelayers and search configured layer
        for (let i = 0; i < mapviewRef.current.map.layers.items.length; i++) {
          let lyr = mapviewRef.current.map.layers.items[i];
          if (
            lyr.type === "feature" &&
            lyr.title.includes(window.FeaturelayerTitle)
          ) {
            console.log("Featurelayer found: ", lyr.parsedUrl.path);
            ctxSetFeaturelayerUrl(lyr.parsedUrl.path);
            ctxSetFeaturelayer(lyr);
            lyrRef.current = lyr;

            lyr.watch("loaded", (newValue) => {
              if (newValue) {
                //als de layer geladen is, dan kunnen we uit de featuretemplate de gemeentenaam halen
                //die gemeentenaam hebben we weer nodig om de juiste plaatsnamen op te halen
                UitlezenGemeentenaam();
              }
            });

            //https://developers.arcgis.com/javascript/latest/sample-code/featurelayer-queryextent/
            //zoom to extent of all features as soon as the layer is loaded
            lyr
              .when(() => {
                return lyr.queryExtent();
              })
              .then((response) => {
                view.goTo(response.extent);
                ctxSetGemeenteExtent(response.extent);
              });

            break;
          }
        }

        //loop over featuretables and search configured table
        for (let i = 0; i < mapviewRef.current.map.tables.items.length; i++) {
          let lyr = mapviewRef.current.map.tables.items[i];
          console.log(window.DetailtableTitle);
          if (lyr.title.includes(window.DetailtableTitle)) {
            console.log("Featuretable found: ", lyr.parsedUrl.path);
            ctxSetDetailTableUrl(lyr.parsedUrl.path);
            break;
          }
        }
      }
    );

    // clean up
    return () => {
      mapview && mapview.destroy();
      handleReady.remove();
    };
  }, [ctxWebMapId]); // only after initial render

  useEffect(() => {
    if (!mapview) {
      return;
    }
    const handle = mapview.on("click", onClick);
    return function removeHandle() {
      handle && handle.remove();
    };

    function onClick(evt) {
      ctxSetTableFilter([]);

      var query = lyrRef.current.createQuery();
      query.geometry = evt.mapPoint; // obtained from a view click event
      query.spatialRelationship = "intersects";
      lyrRef.current.queryFeatures(query).then(function (results) {
        console.log(results.features);
        if (results.features.length > 0) {
          var filter = [
            {
              id: "id",
              value: results.features[0].attributes[window.Objectid],
            },
          ];
          ctxSetTableFilter(filter);
        }
      });
    }
  }, [mapview]);

  useEffect(() => {
    if (mapview !== null && ctxPlannen !== null) {
      if (ctxSelectedPlan !== null) {
        //geometrie opzoeken in de lijst van plannen en zoomen
        ctxPlannen.forEach((element) => {
          if (
            element.attributes[window.Globalid] ===
            ctxSelectedPlan.attributes[window.Globalid]
          ) {
            if (element.geometry === null) {
              alert("Dit element heeft geen geometrie!");
            } else {
              var tmp = element.geometry.extent.clone();
              mapview.extent = tmp.expand(2);
            }
          }
        });
      } else {
        let zoom = mapview.zoom;
        mapview.zoom = zoom - 1;
      }
    }
  }, [ctxSelectedPlan]);

  return <div style={{ height: "100%", width: "100%" }} ref={mapRef} />;
}

export default MainMap;
