import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { useContext } from "react";
import { AppContext } from "./AppContext";
import { makeStyles } from "@material-ui/core/styles";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FilterListIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const MainTableAppBar = (props) => {
  const classes = useStyles();
  const { ctxPlannen, ctxSetTableFilter, ctxTableFilter } =
    useContext(AppContext);

  const resetTableFilter = () => {
    ctxSetTableFilter([]);
  };
  const openFilterDialog = () => {
    props.openfilterdialog();
  };

  return (
    <AppBar position="static" style={{ backgroundColor: window.Color }}>
      <Toolbar variant="dense">
        <Typography variant="button" className={classes.title}>
          {/* Aantal plannen: {ctxTableFilter.length == 0 ? ctxPlannen.length : "filter actief"} */}
          Totaal aantal plannen: {ctxPlannen.length}
        </Typography>
        <FilterListIcon
          fontSize="small"
          style={{ cursor: "pointer", marginRight: "15px" }}
          onClick={openFilterDialog}
          titleAccess="Open Filterscherm"
        ></FilterListIcon>
        <AutorenewIcon
          fontSize="small"
          style={{ cursor: "pointer" }}
          onClick={resetTableFilter}
          titleAccess="Reset filters"
        ></AutorenewIcon>
      </Toolbar>
    </AppBar>
  );
};

export default MainTableAppBar;
