import { updateFeatures } from "@esri/arcgis-rest-feature-layer";
import { NotifyFailure } from "./Notify";

export async function updateFeatureAttr(
  featurelayerurl,
  objid,
  fieldname,
  newvalue,
  usersession,
  callback
) {
  var attr = {};
  attr[window.Objectid] = objid;
  attr[fieldname] = newvalue;

  updateFeatures({
    url: featurelayerurl,
    authentication: usersession,
    features: [
      {
        attributes: attr,
      },
    ],
  })
    .then((response) => {
      console.log("Updated feature: ", response);
      if (response.updateResults[0].success) {
        if (callback) callback(objid, fieldname, newvalue);
      } else {
        NotifyFailure(
          "Wijziging",
          "Opslaan mislukt: " + response.updateResults[0].error.description
        );
      }
    })
    .catch((e) => {
      NotifyFailure("Wijziging", "Opslaan mislukt: " + e.message);
    });
}
