import { store } from "react-notifications-component";

export async function NotifySuccess(title, message) {
  // store.addNotification({
  //   title: title,
  //   message: message,
  //   type: "success",
  //   insert: "top",
  //   container: "bottom-full",
  //   animationIn: ["animate__animated", "animate__fadeIn"],
  //   animationOut: ["animate__animated", "animate__fadeOut"],
  //   dismiss: {
  //     duration: 500,
  //     onScreen: false,
  //   },
  // });
}
export async function NotifyFailure(title, message) {
  store.addNotification({
    title: title,
    message: message,
    type: "danger",
    insert: "top",
    container: "bottom-full",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false,
    },
  });
}
