import React, { useRef, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { AppContext } from "./AppContext";
import { updateFeatureAttr } from "./UpdateFeatureAttr";
import { NotifyFailure } from "./Notify";
import {
  addRemoveDetailRecords,
  shiftDetailRecords,
  UpdateVertrouwelijkheidDetailRecords,
} from "./UpdateDetailRecords";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ModalDialog from "./ModalDialog";
import Link from "@material-ui/core/Link";
import MessageBox from "./MessageBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
registerLocale("nl", nl);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function EditPlanGegevens(props) {
  const {
    ctxSelectedPlan,
    ctxSetSelectedPlan,
    ctxFeaturelayerUrl,
    ctxDetailTableUrl,
    ctxUserSession,
    ctxPlannenFields,
    ctxSetDetailRecordsValid,
    ctxPlaatsnamen,
  } = useContext(AppContext);

  const viewMode = useRef(props.viewmode);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [infoTotaal, setInfoTotaal] = useState(props.totalen);
  const refOnFocus = useRef([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openMessageBox, setOpenMessageBox] = React.useState(false);
  const [openMessageBoxTekst, setOpenMessageBoxTekst] = React.useState("");
  const [openMessageBoxTitel, setOpenMessageBoxTitel] = React.useState("");
  const [dateFields, setDateFields] = React.useState(null);

  useEffect(() => {
    console.log("EditPlanGegevens: infoTotaal gewijzigd ", infoTotaal);
  }, [infoTotaal]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (evt) => {
    var pattern = new RegExp(evt.target.pattern);
    if (!pattern.test(evt.target.value)) {
      evt.target.style.backgroundColor = "#ff000036";
    } else {
      evt.target.style.backgroundColor = "";
    }
  };

  const onBlur = (evt) => {
    var pattern = new RegExp(evt.target.pattern);
    if (!pattern.test(evt.target.value)) {
      // NotifyFailure("Ongeldige invoer", "evt.target.title");
      NotifyFailure("Ongeldige invoer", "Ongeldige invoer");
      return;
    }

    if (
      evt.target.id == refOnFocus.current[0] &&
      evt.target.value != refOnFocus.current[1]
    ) {
      console.log(
        "Value changed: " + evt.target.id + " Value: " + evt.target.value
      );

      //Controles
      if (evt.target.id == "oplevering_eerste") {
        if (
          isNaN(parseInt(evt.target.value)) ||
          evt.target.value < 1950 ||
          evt.target.value > 2100
        ) {
          NotifyFailure("", "Ongeldig jaartal ingevoerd");
          evt.target.value = refOnFocus.current[1];
          return;
        }
        if (
          evt.target.value > ctxSelectedPlan.attributes["oplevering_laatste"] &&
          ctxSelectedPlan.attributes["oplevering_laatste"] != undefined &&
          ctxSelectedPlan.attributes["oplevering_laatste"] != ""
        ) {
          NotifyFailure(
            "",
            "Jaartal laatste moet groter of gelijk jaartal eerste zijn."
          );
          evt.target.value = refOnFocus.current[1];
          return;
        }
      }
      if (evt.target.id == "oplevering_laatste") {
        if (
          isNaN(parseInt(evt.target.value)) ||
          evt.target.value < 1950 ||
          evt.target.value > 2100
        ) {
          NotifyFailure("", "Ongeldig jaartal ingevoerd");
          evt.target.value = refOnFocus.current[1];
          return;
        }
        if (
          ctxSelectedPlan.attributes["oplevering_eerste"] > evt.target.value &&
          ctxSelectedPlan.attributes["oplevering_eerste"] != undefined &&
          ctxSelectedPlan.attributes["oplevering_eerste"] != ""
        ) {
          NotifyFailure(
            "",
            "Jaartal laatste moet groter of gelijk jaartal eerste zijn."
          );
          evt.target.value = refOnFocus.current[1];
          return;
        }
      }

      if (viewMode.current === "View") {
        alert("Viewmode");
        return;
      }

      //Update de waarde in de database
      updateFeatureAttr(
        ctxFeaturelayerUrl,
        ctxSelectedPlan.attributes[window.Objectid],
        evt.target.id,
        evt.target.value,
        ctxUserSession
      );
      ctxSelectedPlan.attributes[evt.target.id] = evt.target.value;
      ctxSetSelectedPlan(ctxSelectedPlan);

      //Controleren of de looptijd van het plan veranderd is
      if (
        evt.target.id == "oplevering_eerste" ||
        evt.target.id == "oplevering_laatste"
      ) {
        //in dit geval veranderd dus de looptijd van het plan.
        //Er moeten jaren bij, of jaren af
        addRemoveDetailRecords(
          ctxSelectedPlan.attributes,
          ctxDetailTableUrl,
          ctxUserSession,
          ctxSelectedPlan.attributes["oplevering_eerste"],
          ctxSelectedPlan.attributes["oplevering_laatste"],
          callbackSetInvalid
        );
      } //else ctxSetDetailRecordsValid(false);
    }
  };

  //om te zorgen dat de detailrecords vernieuwd worden omdat oplevering_eerste of laatste veranderd is
  const callbackSetInvalid = () => {
    console.log("Callback ctxSetDetailRecordsValid");
    ctxSetDetailRecordsValid(false);
  };

  const onFocus = (evt) => {
    refOnFocus.current = [evt.target.id, evt.target.value];
  };

  const handleChangeSelect = (evt) => {
    if (viewMode.current === "View") {
      return;
    }
    console.log(
      "Value changed: " + evt.target.id + " Value: " + evt.target.value
    );
    updateFeatureAttr(
      ctxFeaturelayerUrl,
      ctxSelectedPlan.attributes[window.Objectid],
      evt.target.id,
      evt.target.value,
      ctxUserSession
    );
    ctxSelectedPlan.attributes[evt.target.id] = evt.target.value;
    ctxSetSelectedPlan(ctxSelectedPlan);

    //indien de vertrouwelijkeheid veranderd, dan moet ook de vertrouwelijkheid van de detailrecords aangepast worden
    if (evt.target.id === "vertrouwelijkheid") {
      UpdateVertrouwelijkheidDetailRecords(
        ctxSelectedPlan.attributes,
        ctxDetailTableUrl,
        ctxUserSession
      );
    }
  };

  const callbackUpdated = (objid, fieldname, value) => {
    ctxSelectedPlan.attributes[fieldname] = value;
    ctxSetSelectedPlan(ctxSelectedPlan);
    setDateFields(value);
  };

  const handleDateChange = (date, id) => {
    if (viewMode.current === "View") {
      return;
    }
    updateFeatureAttr(
      ctxFeaturelayerUrl,
      ctxSelectedPlan.attributes[window.Objectid],
      id,
      date,
      ctxUserSession,
      callbackUpdated
    );
  };

  function getField(fieldname) {
    for (var i = 0; i < ctxPlannenFields.length; i++) {
      if (ctxPlannenFields[i].name === fieldname) return ctxPlannenFields[i];
    }
    return null;
  }

  const Domain = (props) => {
    var domein = props.domain.slice();

    //Als het veld 'woonplaats' is, dan krijgt wordt de dropdown gevuld met woonplaatsen
    //Op basis van de Gemeentenaam zijn die woonplaatsen al eerder opgehaald
    if (props.id === "woonplaats") {
      console.log(ctxPlaatsnamen);
      if (ctxPlaatsnamen === null) domein = [];
      else domein = [...ctxPlaatsnamen];
    }

    //lege waarde aan de array toekennen zodat die gekozen kan worden als de value==""
    let cv = {};
    cv["code"] = "";
    cv["name"] = "";
    domein.push(cv);

    //use == instead of ===
    //otherwise null is not the same as undefined
    let value = props.value == undefined ? "" : props.value;
    return domein.map((codedValue) => {
      return (
        <option
          key={codedValue.code}
          value={codedValue.code}
          selected={codedValue.code === value}
        >
          {codedValue.name}
        </option>
      );
    });
  };

  const DropDown = (props) => {
    var editable = props.editable;
    if (viewMode.current === "View") {
      editable = false;
    }
    return (
      <div style={{ float: "left", clear: "none", display: "inline-flex" }}>
        <label
          style={{
            display: "inline-block",
            width: "150px",
            textAlign: "left",
            fontSize: "14px",
          }}
        >
          {props.alias}
          <div className="tooltip">
            {props.tooltip !== undefined && props.tooltip !== "" ? (
              <HelpOutlineIcon
                onMouseEnter={(e) =>
                  tooltipEnter(e, props.tooltip, props.field)
                }
                onMouseLeave={(e) => tooltipLeave(e, props.field)}
                fontSize="small"
                color="primary"
                style={{ fontSize: "0.75rem" }}
              ></HelpOutlineIcon>
            ) : (
              <div></div>
            )}
          </div>
        </label>
        <select
          onChange={handleChangeSelect}
          key={props.id}
          id={props.id}
          disabled={!editable}
          style={{
            display: "inline-block",
            width: "225px",
            padding: "10px",
            textAlign: "left",
            margin: "5px",
          }}
        >
          <Domain
            id={props.id}
            value={props.value}
            domain={props.domain}
          ></Domain>
        </select>
      </div>
    );
  };

  const DatumVeld = (props) => {
    var editable = props.editable;
    if (viewMode.current === "View") {
      editable = false;
    }
    return (
      <div style={{ float: "left", clear: "none", display: "inline-flex" }}>
        <label
          style={{
            display: "inline-block",
            width: "150px",
            textAlign: "left",
            fontSize: "14px",
          }}
        >
          {props.alias}
          <div className="tooltip">
            {props.tooltip !== undefined && props.tooltip !== "" ? (
              <HelpOutlineIcon
                onMouseEnter={(e) =>
                  tooltipEnter(e, props.tooltip, props.field)
                }
                onMouseLeave={(e) => tooltipLeave(e, props.field)}
                fontSize="small"
                color="primary"
                style={{ fontSize: "0.75rem" }}
              ></HelpOutlineIcon>
            ) : (
              <div></div>
            )}
          </div>
        </label>
        <div
          style={{
            display: "inline-block",
            margin: "5px",
            width: "225px",
          }}
        >
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            readOnly={!editable}
            id={props.id}
            key={props.id}
            locale="nl"
            selected={props.value}
            onChange={(date) => handleDateChange(date, props.id)}
          />
        </div>
      </div>
    );
  };

  function tooltipLeave(e, veldnaam) {
    if (document.getElementById("tooltip_" + veldnaam)) {
      var div = document.getElementById("tooltip_" + veldnaam);
      div.parentNode.removeChild(div);
    }
    return false;
  }
  function tooltipEnter(e, tekst, veldnaam) {
    var div = document.createElement("div");
    div.className = "customtooltipdiv";
    div.style.backgroundColor = window.Color;
    div.style.left = e.clientX + "px";
    div.style.top = e.clientY + "px";
    div.innerHTML = tekst;
    div.id = "tooltip_" + veldnaam;
    document.getElementsByTagName("body")[0].appendChild(div);
    return false;
  }

  const TekstVeldMetLabel = (props) => {
    var editable = props.editable;
    if (viewMode.current === "View") {
      editable = false;
    }
    if (props.multiline) {
      return (
        <div style={{ float: "left", clear: "none", display: "inline-flex" }}>
          <label
            style={{
              display: "inline-block",
              width: "150px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            {props.alias}
            <div className="tooltip">
              {props.tooltip != undefined && props.tooltip != "" ? (
                <HelpOutlineIcon
                  onMouseEnter={(e) =>
                    tooltipEnter(e, props.tooltip, props.field)
                  }
                  onMouseLeave={(e) => tooltipLeave(e, props.field)}
                  fontSize="small"
                  color="primary"
                  style={{ fontSize: "0.75rem" }}
                ></HelpOutlineIcon>
              ) : (
                <div></div>
              )}
            </div>
          </label>

          <textarea
            style={{
              display: "inline-block",
              width: "200px",
              padding: "10px",
              textAlign: "left",
              margin: "5px",
              verticalAlign: "top",
              fontFamily: "Roboto, Helvetic, Arial, sans-serif",
            }}
            autoComplete="off"
            disabled={!editable}
            rows="5"
            type="text"
            name={props.field}
            id={props.field}
            defaultValue={props.waarde}
            onFocus={onFocus}
            onBlur={onBlur}
          ></textarea>
        </div>
      );
    } else {
      return (
        <div style={{ float: "left", clear: "none", display: "inline-flex" }}>
          <label
            style={{
              display: "inline-block",
              width: "150px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            {props.alias}
            <div className="tooltip">
              {props.tooltip != undefined && props.tooltip != "" ? (
                <HelpOutlineIcon
                  onMouseEnter={(e) =>
                    tooltipEnter(e, props.tooltip, props.field)
                  }
                  onMouseLeave={(e) => tooltipLeave(e, props.field)}
                  fontSize="small"
                  color="primary"
                  style={{ fontSize: "0.75rem" }}
                ></HelpOutlineIcon>
              ) : (
                <div></div>
              )}
            </div>
          </label>

          <input
            style={{
              display: "inline-block",
              width: "200px",
              padding: "10px",
              textAlign: "left",
              margin: "5px",
            }}
            autoComplete="off"
            disabled={!editable}
            name={props.field}
            id={props.field}
            defaultValue={props.waarde}
            onFocus={onFocus}
            onBlur={onBlur}
            //title={getErrorMessage(props.type)} //sets HTML error message
            title=""
            type={getType(props.type)}
            onChange={onChange}
            pattern={getPattern(props.type)}
          ></input>
        </div>
      );
    }
  };

  const getPattern = (type) => {
    switch (type) {
      case "small-integer":
        return "^[0-9]*$|^$";
      case "string":
        return "";
      case "double":
        return "^[0-9]+([.,][0-9]{1,3})?$|^$";
      default:
        return "";
    }
  };
  const getType = (type) => {
    switch (type) {
      case "small-integer":
        return "number";
      case "string":
        return "text";
      case "double":
        return "number";
      default:
        return "text";
    }
  };
  const getErrorMessage = (type) => {
    switch (type) {
      case "small-integer":
        return "Vul hier een heel getal in";
      case "string":
        return "Vul hier een tekst in";
      case "double":
        return "Vul hier een getal in, evt. met decimalen";
      case "date":
        return "Vul hier een datum in";
      default:
        return "";
    }
  };

  const PlanAttributen = (propsdetail) => {
    if (propsdetail.categorie == "") return <div></div>;

    let velden = propsdetail.categorie.split(",");
    return velden.map((veld_in_config) => {
      veld_in_config = veld_in_config.trim();
      if (veld_in_config == "") return <div></div>;

      var waarde = "";
      if (
        ctxSelectedPlan !== null &&
        ctxSelectedPlan.attributes[veld_in_config] !== undefined &&
        veld_in_config != ""
      ) {
        waarde = ctxSelectedPlan.attributes[veld_in_config];
      }
      var field = getField(veld_in_config);
      if (field === null) {
        //Veld komt blijkbaar niet voor in het view, melden, want foute configuratie
        //alert("EditPlangegevens-field not found in view: " + veld_in_config);
        return <div></div>;
      }
      if (field.type == "date") {
        setDateFields(waarde);
      }
      //gemeente, regio en provincie zijn domainvelden, maar de inhoud ligt vast, dus geen dropdown van maken
      //daarnaast worden ze ook readonly gezet.
      if (
        veld_in_config !== "gemeente" &&
        veld_in_config !== "regio" &&
        veld_in_config !== "provincie" &&
        field.domain !== null
      ) {
        return (
          <DropDown
            key={veld_in_config}
            id={veld_in_config}
            alias={field.alias}
            value={waarde}
            domain={field.domain.codedValues}
            editable={field.editable}
            tooltip={getTooltip(veld_in_config)}
          ></DropDown>
        );
      } else if (field.type == "date") {
        return (
          <DatumVeld
            editable={field.editable}
            alias={field.alias}
            field={veld_in_config}
            id={veld_in_config}
            key={veld_in_config}
            value={dateFields}
            tooltip={getTooltip(veld_in_config)}
          />
        );
      } else {
        return (
          <TekstVeldMetLabel
            editable={
              veld_in_config !== "gemeente" &&
              veld_in_config !== "regio" &&
              veld_in_config !== "provincie"
                ? field.editable
                : false
            }
            alias={field.alias}
            field={veld_in_config}
            key={veld_in_config}
            waarde={waarde}
            multiline={veldIsMultiline(veld_in_config)}
            tooltip={getTooltip(veld_in_config)}
            type={field.type}
          ></TekstVeldMetLabel>
        );
      }
    });
  };

  const veldIsMultiline = (veld_in_config) => {
    if (window.multiline_fields.includes(veld_in_config)) return true;
    return false;
  };

  const controleerGegevens = () => {
    console.log("Controleer gegevens: ", window.VerplichteVelden);
    var verplichtevelden = window.VerplichteVelden.split(",");
    if (verplichtevelden.length === 0) return;
    var tekst = "De volgende velden moeten nog gevuld worden: ";
    for (let index = 0; index < verplichtevelden.length; index++) {
      const veldnaam = verplichtevelden[index].trim();
      if (
        ctxSelectedPlan.attributes[veldnaam] === "" ||
        ctxSelectedPlan.attributes[veldnaam] === undefined ||
        ctxSelectedPlan.attributes[veldnaam] === null
      ) {
        //alias ophalen om te tonen aan gebruiker
        var field = getField(veldnaam);
        if (field === null) tekst = tekst + " " + veldnaam;
        else tekst = tekst + " " + field.alias + " , ";
      }
    }
    setOpenMessageBoxTitel("Verplichte velden");
    setOpenMessageBoxTekst(tekst);
    setOpenMessageBox(true);
  };

  const getTooltip = (fieldname) => {
    // console.log("GET TOOLTIP: " + fieldname);
    let tooltiptext = "";
    window.Tooltips.forEach((tooltipitem) => {
      if (fieldname == tooltipitem.field) {
        tooltiptext = tooltipitem.text;
      }
    });
    return tooltiptext;
  };

  const responseMessageBox = (value) => {
    setOpenMessageBox(false);
  };

  const responseModalDialog = (value) => {
    console.log("Planning doorschuiven: ", value);
    setOpenModal(false);
    if (value) {
      var eerste = parseInt(ctxSelectedPlan.attributes["oplevering_eerste"]);
      var laatste = parseInt(ctxSelectedPlan.attributes["oplevering_laatste"]);
      if (isNaN(eerste) || isNaN(laatste)) {
        setOpenMessageBoxTitel("Planning doorschuiven");
        setOpenMessageBoxTekst(
          "Het doorschuiven van de planning is niet mogelijk."
        );
        setOpenMessageBox(true);
        return;
      } else {
        ctxSelectedPlan.attributes["oplevering_eerste"] = eerste + 1;
        ctxSelectedPlan.attributes["oplevering_laatste"] = laatste + 1;
        //Update de eerste en laatste oplevering in de database
        updateFeatureAttr(
          ctxFeaturelayerUrl,
          ctxSelectedPlan.attributes[window.Objectid],
          "oplevering_eerste",
          eerste + 1,
          ctxUserSession
        );
        updateFeatureAttr(
          ctxFeaturelayerUrl,
          ctxSelectedPlan.attributes[window.Objectid],
          "oplevering_laatste",
          laatste + 1,
          ctxUserSession
        );

        shiftDetailRecords(
          ctxSelectedPlan.attributes,
          ctxDetailTableUrl,
          ctxUserSession,
          callbackSetInvalid
        );
      }
    }
  };

  const PlanningDoorschuiven = () => {
    var eerste = parseInt(ctxSelectedPlan.attributes["oplevering_eerste"]);
    var laatste = parseInt(ctxSelectedPlan.attributes["oplevering_laatste"]);
    if (isNaN(eerste) || isNaN(laatste)) {
      setOpenMessageBoxTitel("Planning doorschuiven");
      setOpenMessageBoxTekst(
        "Het doorschuiven van de planning is niet mogelijk."
      );
      setOpenMessageBox(true);
      return;
    } else setOpenModal(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: window.Color }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab data-tabstatus={value === 0} label="Basis" {...a11yProps(0)} />
          <Tab data-tabstatus={value === 1} label="Status" {...a11yProps(1)} />
          <Tab
            data-tabstatus={value === 2}
            label="Kwalitatief"
            {...a11yProps(2)}
          />
          <Tab
            data-tabstatus={value === 3}
            label={window.Tabname_Extra}
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div style={{ overflow: "auto" }}>
          <table style={{ tableLayout: "fixed" }}>
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_basis_column1}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_basis_column2}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_basis_column3}
                  ></PlanAttributen>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ float: "right" }}>
          <Link
            style={{
              color: window.Color,
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current === "View" ? "none" : "",
            }}
            href="#"
            onClick={PlanningDoorschuiven}
            variant="body2"
          >
            Verschuif planning 1 jaar vooruit
          </Link>
          <Link
            style={{
              color: window.Color,
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current === "View" ? "none" : "",
            }}
            href="#"
            variant="body2"
            onClick={controleerGegevens}
          >
            Controleer gegevens
          </Link>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div style={{ overflow: "auto" }}>
          <table style={{ tableLayout: "fixed" }}>
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_status_column1}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_status_column2}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_status_column3}
                  ></PlanAttributen>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ float: "right" }}>
          <Link
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current === "View" ? "none" : "",
            }}
            href="#"
            variant="body2"
            onClick={controleerGegevens}
          >
            Controleer gegevens
          </Link>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={{ overflow: "auto" }}>
          <table style={{ tableLayout: "fixed" }}>
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_kwalitatief_column1}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    categorie={window.Tabblad_kwalitatief_column2}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  {" "}
                  <PlanAttributen
                    categorie={window.Tabblad_kwalitatief_column3}
                  ></PlanAttributen>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ float: "right" }}>
          <Link
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current === "View" ? "none" : "",
            }}
            href="#"
            variant="body2"
            onClick={controleerGegevens}
          >
            Controleer gegevens
          </Link>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div style={{ overflow: "auto" }}>
          <table style={{ tableLayout: "fixed" }}>
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    selectedplan={ctxSelectedPlan}
                    categorie={window.Tabblad_extra_column1}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    selectedplan={ctxSelectedPlan}
                    categorie={window.Tabblad_extra_column2}
                  ></PlanAttributen>
                </td>
                <td style={{ width: "33%", textAlign: "left" }}>
                  <PlanAttributen
                    selectedplan={ctxSelectedPlan}
                    categorie={window.Tabblad_extra_column3}
                  ></PlanAttributen>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ float: "right" }}>
          <Link
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "underline",
              display: viewMode.current === "View" ? "none" : "",
            }}
            href="#"
            variant="body2"
            onClick={controleerGegevens}
          >
            Controleer gegevens
          </Link>
        </div>
      </TabPanel>
      <ModalDialog
        open={openModal}
        title={"Planning doorschuiven?"}
        question="Hiermee wordt de complete planning 1 jaar doorgeschoven! Dit kan niet automatisch ongedaan gemaakt worden. Wilt u doorgaan?"
        handleClose={responseModalDialog}
      ></ModalDialog>
      <MessageBox
        open={openMessageBox}
        title={openMessageBoxTitel}
        question={openMessageBoxTekst}
        handleClose={responseMessageBox}
      ></MessageBox>
    </div>
  );
}
