export async function getDetailRecords(selectedPlan, urltable, token) {
  let globalid = selectedPlan[window.Globalid];
  const params = {
    where: "parent_globalid='" + globalid + "'",
    f: "json",
    orderByFields: "jaartal",
    outFields: ["*"],
    token: token,
  };
  const paramVals = [];
  for (const k in params) {
    const val = encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    paramVals.push(val);
  }
  const url = `${urltable}/query?${paramVals.join("&")}`;
  const data = await fetch(url);
  const { fields, features } = await data.json();
  return [fields, features];
}
