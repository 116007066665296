import React, { useContext, useRef } from "react";
import { AppContext } from "./AppContext";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

function FilterDialog(props) {
  const { ctxPlannenFields, ctxSetTableFilter } = useContext(AppContext);

  const filterFieldnames = useRef(null);
  const filterComparison = useRef(null);
  const filterwaardeInput = useRef(null);

  function getField(fieldname) {
    for (var i = 0; i < ctxPlannenFields.length; i++) {
      if (ctxPlannenFields[i].name === fieldname) return ctxPlannenFields[i];
    }
    return null;
  }
  if (!props.isfilterdialogopen) return null;

  function saveFilter() {
    let fieldnamesList = filterFieldnames.current;
    let selectedoption = fieldnamesList.selectedOptions[0]; //get the first of selected items
    let planfield = JSON.parse(selectedoption.id); //id contains jsonobject

    var filter = [
      {
        id: planfield.name,
        value: filterwaardeInput.current.value,
      },
    ];
    ctxSetTableFilter(filter);
    props.closefilterdialog(true);
  }

  const onSelectChange = (evt) => {
    let vergelijkingDOM = filterComparison.current;
    let fieldnamesList = filterFieldnames.current;
    let selectedoption = fieldnamesList.selectedOptions[0]; //get the first of selected items
    let planfield = JSON.parse(selectedoption.id); //id contains jsonobject
    console.log(planfield.type);
    switch (planfield.type) {
      case "esriFieldTypeString":
        vergelijkingDOM.innerHTML = " bevat ";
        break;
      case "esriFieldTypeSmallInteger":
        vergelijkingDOM.innerHTML = " is gelijk aan ";
        break;
      case "esriFieldTypeDouble":
        vergelijkingDOM.innerHTML = " is gelijk aan ";
        break;
      case "esriFieldTypeDate":
        vergelijkingDOM.innerHTML = " is gelijk aan (dd-mm-jjjj)";
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ width: "400px" }}>
      <Dialog
        open={props.isfilterdialogopen}
        onClose={() => props.closefilterdialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        width="100%"
      >
        <DialogTitle id="alert-dialog-title">Filter</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <select ref={filterFieldnames} onChange={onSelectChange}>
              {window.PlantableFields.map((item, index) => {
                {
                  let planfield = getField(item.field);
                  if (planfield)
                    return (
                      <option key={index} id={JSON.stringify(planfield)}>
                        {planfield.alias}
                      </option>
                    );
                  else return null;
                }
              })}
            </select>
            <label ref={filterComparison}> bevat </label>
            <input ref={filterwaardeInput} placeholder="filterwaarde"></input>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => saveFilter()} color="primary" autoFocus>
            Toepassen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FilterDialog;
