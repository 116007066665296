import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import "./App.css";
import { AppContext } from "./AppContext";
import { ReactTable } from "./ReactTable";
import { formatDateToLocalInputDate } from "@material-ui/data-grid";

//Alternatief: https://codesandbox.io/s/23jy4py5yr?file=/Utils.js

export default function MainTable(props) {
  const {
    ctxPlannen,
    ctxSetPlannen,
    ctxFeaturelayer,
    ctxUserSession,
    ctxTableFilter,
    ctxPlannenFields,
    ctxSetPlannenFields,
    ctxSelectedPlan,
  } = useContext(AppContext);

  const [kolommen, setKolommen] = useState([]);
  const [rijen, setRijen] = useState([]);
  const [hiddenkolommen, setHiddenkolommen] = useState([]);

  //Recursive
  const HaalAllePlannen = (allFeatures, callback) => {
    var query = ctxFeaturelayer.createQuery();
    query.start = allFeatures.length;
    query.num = 1000;
    //query.where = "woonplaats like 'Cr%'";
    ctxFeaturelayer.queryFeatures(query).then(function (response) {
      if (allFeatures.length === 0) {
        ctxSetPlannenFields(response.fields);
      }
      if (response.exceededTransferLimit) {
        allFeatures = allFeatures.concat(response.features);
        HaalAllePlannen(allFeatures, callback);
      } else {
        allFeatures = allFeatures.concat(response.features);
        callback(allFeatures);
      }
    });
  };

  const OphalenPlannenGereed = (allFeatures) => {
    console.log("Aantal plannen gevonden: ", allFeatures.length);
    ctxSetPlannen(allFeatures);
    VulRijen(allFeatures);
  };

  const VulRijen = (allFeatures, kolommen) => {
    if (allFeatures.length > 0) {
      var tmprows = [];
      allFeatures.forEach((plan) => {
        var rij = {};
        //er moet verplicht een id-kolom zijn. Deze vullen we met OBJECTID
        rij["id"] = plan.attributes[window.Objectid];
        if (kolommen) {
          kolommen.forEach((kolom) => {
            console.log(kolom);
          });
        } else {
          window.PlantableFields.forEach((column) => {
            rij[column.field] = plan.attributes[column.field];
          });
        }
        tmprows.push(rij);
      });
      setRijen(tmprows);
    }
  };

  //featurelayer-state is gewijzigd: door het laden van de map(view)
  //Plannen uit de featurelayer lezen en de rijen van de tabel vullen
  useEffect(() => {
    if (ctxFeaturelayer != null && ctxUserSession != null) {
      //featurelayer is gewijzigd: ophalen plannen en in tabel zetten
      let allFeatures = [];
      //Deze functie is recursive met daarbinnen async calls. Wanneer ie helemaal gereed is, roept ie 'OphalenPlannenGereed' aan
      HaalAllePlannen(allFeatures, OphalenPlannenGereed);
    }
  }, [ctxFeaturelayer, props.dataValid]);

  //filter van de tabel is gewijzigd (omdat een feature op de map is aangeklikt bv)
  useEffect(() => {}, [ctxTableFilter]);

  //de attribuutvelden van de plannen uitlezen, bewaren en kolommen van tabel maken
  useEffect(() => {
    if (ctxPlannenFields != null && ctxPlannenFields.length > 0) {
      //er moet verplicht een id-kolom zijn. Deze vullen we met OBJECTID
      var tmpcolumns = [];
      var newcolumn = {};
      newcolumn["Header"] = "id";
      newcolumn["accessor"] = "id";
      newcolumn["defaultCanSort"] = true;
      newcolumn["width"] = 50;
      tmpcolumns.push(newcolumn);

      //overige kolommen voor de plannen-tabel uit de config lezen
      //apart lijstje voor hiddencolumns maken (worden niet getoond, maar kan wel op gefilterd worden)
      var hidden = [];
      window.PlantableFields.forEach((column) => {
        newcolumn = {};
        newcolumn["Header"] = getAlias(column.field);
        newcolumn["accessor"] =
          column.field == "ph_date1"
            ? (row) => formatDate(row[column.field])
            : column.field;
        newcolumn["defaultCanSort"] = true;
        tmpcolumns.push(newcolumn);
        if (!column.show) {
          hidden.push(column.field);
        }
      });
      setKolommen(tmpcolumns);
      //hidden.push("id");
      setHiddenkolommen(hidden);
    }
  }, [ctxPlannenFields]);

  function formatDate(d) {
    if (d) {
      var date = new Date(d);
      return (
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
      );
    } else {
      return d;
    }
  }

  function getAlias(fieldname) {
    for (var i = 0; i < ctxPlannenFields.length; i++) {
      if (ctxPlannenFields[i].name === fieldname)
        return ctxPlannenFields[i].alias;
    }
    return fieldname;
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "85%", width: "100%", overflowX: "auto" }}>
        <ReactTable
          rows={rijen}
          columns={kolommen}
          hidden={hiddenkolommen}
          isfilterdialogopen={props.isfilterdialogopen}
          closefilterdialog={props.closefilterdialog}
        ></ReactTable>
      </div>
      <div
        style={{
          position: "relative",
          bottom: "0px",
        }}
      >
        <Button
          id="add"
          variant="outlined"
          style={{
            margin: "5px",
            backgroundColor: window.Color,
            color: "white",
          }}
          onClick={props.buttontableclicked()}
        >
          Toevoegen
        </Button>
        <Button
          id="view"
          disabled={ctxSelectedPlan == null}
          variant="outlined"
          style={{
            margin: "5px",
            backgroundColor: window.Color,
            opacity: ctxSelectedPlan == null ? "0.5" : "1",
            color: "white",
          }}
          onClick={props.buttontableclicked()}
        >
          Bekijken
        </Button>
        <Button
          id="modify"
          disabled={ctxSelectedPlan == null}
          variant="outlined"
          style={{
            margin: "5px",
            backgroundColor: window.Color,
            opacity: ctxSelectedPlan == null ? "0.5" : "1",
            color: "white",
          }}
          onClick={props.buttontableclicked()}
        >
          Wijzigen
        </Button>
        <Button
          id="delete"
          disabled={ctxSelectedPlan == null}
          variant="outlined"
          style={{
            margin: "5px",
            backgroundColor: window.Color,
            color: "white",
            opacity: ctxSelectedPlan == null ? "0.5" : "1",
          }}
          onClick={props.buttontableclicked()}
        >
          Verwijderen
        </Button>
      </div>
    </div>
  );
}
