import { addFeatures } from "@esri/arcgis-rest-feature-layer";
import { NotifyFailure, NotifySuccess } from "./Notify";

export async function addPlanRelated(
  usersession,
  selectedPlanAttr,
  urlrelated,
  plancapaciteit,
  callback,
  callbackOnError
) {
  let parent_globalid = selectedPlanAttr[window.Globalid];
  let jaarstart = selectedPlanAttr["oplevering_eerste"];
  let jaareind = selectedPlanAttr["oplevering_laatste"];
  if (
    plancapaciteit === undefined ||
    plancapaciteit === 0 ||
    jaarstart === undefined ||
    jaarstart === 0 ||
    jaareind === undefined ||
    jaareind === 0
  ) {
    NotifyFailure(
      "",
      "Plancapaciteit, jaartal eerste en laatste oplevering moeten ingevuld zijn."
    );
    callbackOnError();
    return;
  }

  if (jaarstart > jaareind) {
    NotifyFailure(
      "",
      "Jaartal laatste moet groter of gelijk jaartal eerste zijn."
    );
    callbackOnError();
    return;
  }

  //dus stel oplevering 1e = 2022 en oplevering laatste = 2026
  //dan 2022, 2023, 2024, 2025, 2026 -> 5 jaar oftwel laatste minus eerste plus 1
  let duur = jaareind - jaarstart + 1;
  if (duur <= 0) {
    NotifyFailure("", "Duur moet groter zijn dan nul.");
    callbackOnError();
    return;
  }

  //de plancapaciteit zo gelijkmatig mogelijk verdelen over de jaren
  var div = Math.ceil(plancapaciteit / duur); //levert een div welke groter of precies goed is
  var mod = div * duur - plancapaciteit; //het overschot uitrekenen. Kan ook 0 zijn.
  let jaren = [];
  console.log("Div:", div);
  console.log("Mod:", mod);
  for (let jaartal = jaarstart; jaartal <= jaareind; jaartal++) {
    let aantal = div;
    if (mod !== 0 && jaartal === jaareind) {
      aantal = div - mod; //bij het laatste jaar het overschot(mod) aftrekken van de div
    }

    let jaar = {
      attributes: {
        parent_globalid: parent_globalid,
        jaartal: jaartal,
        onbekend_onbekend: aantal,
        //uitbreiding met deze drie velden vanaf 1.09
        vertrouwelijkheid: selectedPlanAttr["vertrouwelijkheid"],
        gemeente: selectedPlanAttr["gemeente"],
        regio: selectedPlanAttr["regio"],
      },
    };
    jaren.push(jaar);
    console.log("Jaar: " + jaartal + " ->" + aantal);
  }

  addFeatures({
    url: urlrelated,
    authentication: usersession,
    features: jaren,
  })
    .then((response) => {
      console.log("AddRelated succes: ", response);
      NotifySuccess("Detailplanning", "Aangemaakt");
      callback();
    })
    .catch((e) => {
      console.log("AddRelated failed: ", e);
      NotifyFailure("Detailplanning", "Aanmaken mislukt: " + e.message);
      callbackOnError();
    });
}

export async function addPlanRelatedJaar(
  selectedPlanAttr,
  parentGlobalid,
  urlrelated,
  jaartal,
  usersession,
  callback
) {
  let jaren = [];
  let jaar = {
    attributes: {
      parent_globalid: parentGlobalid,
      jaartal: jaartal,
      //uitbreiding met deze drie velden vanaf 1.09
      vertrouwelijkheid: selectedPlanAttr["vertrouwelijkheid"],
      gemeente: selectedPlanAttr["gemeente"],
      regio: selectedPlanAttr["regio"],
    },
  };
  jaren.push(jaar);

  addFeatures({
    url: urlrelated,
    authentication: usersession,
    features: jaren,
  })
    .then((response) => {
      console.log("AddRelatedJaar succes: ", response);
      NotifySuccess("Jaar " + jaartal, "Toegevoegd");
      callback();
    })
    .catch((e) => {
      console.log("AddRelatedJaar failed: ", e);
      NotifyFailure("Toevoegen jaar " + jaartal, "Mislukt: " + e.message);
    });
}
