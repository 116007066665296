//https://www.npmjs.com/package/@esri/arcgis-rest-request
import { request } from "@esri/arcgis-rest-request";

export async function searchWebmap(onlineurl, callbackfunc, token) {
  //https://developers.arcgis.com/rest/users-groups-and-items/search.htm
  //TODO: replace by ??: https://esri.github.io/arcgis-rest-js/api/feature-layer/queryFeatures/
  //Portal voorbeeld: https://machine.domain.com/webadaptor/sharing/rest/search?q=park&f=pjson
  const filter = "tags:" + window.WebmapTagId;
  const params = {
    q: "invoer",
    f: "json",
    filter: filter,
    token: token,
    searchFields: "title,tags",
  };
  const paramVals = [];
  for (const k in params) {
    const val = encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    paramVals.push(val);
  }
  const url = `${onlineurl}/search/suggest=${
    window.WebmapTagId
  }?${paramVals.join("&")}`;
  console.log("SearchWebMap start");
  // const url = `${onlineurl}/search?${paramVals.join("&")}`;
  request(url, {
    responseType: "json",
  }).then(function (response) {
    if (response.results.length > 0) {
      var aantalgevonden = 0;
      for (let index = 0; index < response.results.length; index++) {
        const webmap = response.results[index];
        if (webmap.type === "Web Map") {
          console.log("WebmapId: ", webmap.id);
          aantalgevonden++;
        }
      }
      if (aantalgevonden > 1) {
        alert("Meer dan 1 webmap gevonden! Zie console.");
      }
      if (aantalgevonden === 0) {
        alert("Geen webmap gevonden!");
      }
      for (let index = 0; index < response.results.length; index++) {
        const webmap = response.results[index];
        if (webmap.type === "Web Map") {
          if (aantalgevonden > 1) {
            if (window.confirm(webmap.title)) {
              console.log("WebmapId: ", webmap.id);
              callbackfunc(webmap.id);
              break;
            }
          } else {
            console.log("WebmapId: ", webmap.id);
            callbackfunc(webmap.id);
            break;
          }
        }
      }
    } else {
      alert(`Webmap niet gevonden (${filter})`);
    }
  });
}
