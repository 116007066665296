import { deleteFeatures } from "@esri/arcgis-rest-feature-layer";
import { NotifySuccess, NotifyFailure } from "./Notify";

export async function deletePlan(
  selectedPlan,
  url,
  urlrelated,
  usersession,
  callback
) {
  let objectid = selectedPlan[window.Objectid];
  let globalid = selectedPlan[window.Globalid];

  //eerst related table verwijderen
  deleteFeatures({
    url: urlrelated,
    authentication: usersession,
    where: "parent_globalid='" + globalid + "'",
  })
    .then((response) => {
      console.log("DeleteRelated: ", response);
      NotifySuccess("Verwijderen Detailplanning", "Gereed");
    })
    .catch((e) => {
      NotifyFailure("Verwijderen Detailplanning", "Mislukt: " + e.message);
    });

  //daarna het plan
  deleteFeatures({
    url: url,
    authentication: usersession,
    objectIds: objectid,
  })
    .then((response) => {
      console.log("DeletePlan: ", response);
      NotifySuccess("Verwijderen Plan", "Gereed");
      callback();
    })
    .catch((e) => {
      NotifyFailure("Verwijderen Plan", "Mislukt: " + e.message);
    });
}
